import { createGlobalStyle } from 'styled-components';

import ebgaramondBold from '../assets/fonts/EBGaramond-Bold.ttf';
import ebgaramondMedium from '../assets/fonts/EBGaramond-Medium.ttf';
import poppinsRegular from '../assets/fonts/Poppins-Regular.ttf';
import poppinsMedium from '../assets/fonts/Poppins-Medium.ttf';
import poppinsSemiBold from '../assets/fonts/Poppins-SemiBold.ttf';
import poppinsBold from '../assets/fonts/Poppins-Bold.ttf';

const Typography = createGlobalStyle`
  @font-face {
    font-display: swap;
    font-family: 'EB Garamond';
    src: local('EB Garamond Bold'), local('EBGaramond-Bold'), url(${ebgaramondBold}) format('truetype');
    font-style: normal;
    font-weight: 700;
  }
  @font-face {
    font-display: swap;
    font-family: 'EB Garamond';
    src: local('EB Garamond Medium'), local('EBGaramond-Medium'), url(${ebgaramondMedium}) format('truetype');
    font-style: normal;
    font-weight: 500;
  }
  @font-face {
    font-display: swap;
    font-family: 'Poppins';
    src: local('Poppins Regular'), local('Poppins-Regular'), url(${poppinsRegular}) format('truetype');
    font-style: normal;
    font-weight: 400;
  }
  @font-face {
    font-display: swap;
    font-family: 'Poppins';
    src: local('Poppins Medium'), local('Poppins-Medium'), url(${poppinsMedium}) format('truetype');
    font-style: normal;
    font-weight: 500;
  }
  @font-face {
    font-display: swap;
    font-family: 'Poppins';
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(${poppinsSemiBold}) format('truetype');
    font-style: normal;
    font-weight: 600;
  }
  @font-face {
    font-display: swap;
    font-family: 'Poppins';
    src: local('Poppins Bold'), local('Poppins-Bold'), url(${poppinsBold}) format('truetype');
    font-style: normal;
    font-weight: 700;
  }
`;

export default Typography;
