import React, { useState } from 'react';
import { Link } from 'gatsby';
import { NavStyles } from '../styles/NavStyles';
import Logo from './Logo';

export default function Nav() {
  const handleProps = ({ isPartiallyCurrent }) =>
    isPartiallyCurrent ? { className: 'activeLink' } : null;

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleItemClick = () => {
    setExpanded(false)
  };

  return (
    <NavStyles>
      <Link to="/">
        <Logo />
      </Link>
      <button type="button" aria-controls="menu" aria-expanded={expanded} onClick={toggleExpanded}></button>
      <nav>
      <ul>
        <li>
          <Link to="/books" onClick={handleItemClick}>Books</Link>
        </li>
        <li>
          <Link to="/projects" getProps={handleProps} onClick={handleItemClick}>
            Projects
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={handleItemClick}>Contact</Link>
        </li>
      </ul>
      </nav>
    </NavStyles>
  );
}
