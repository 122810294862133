import React from 'react';
import styled from 'styled-components';

const FooterStyles = styled.footer`
  align-items: center;
  color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;
  font-size: clamp(0.9rem, 2vw, 1rem);
  font-weight: 400;
  .dg {
    color: var(--primary-color);
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <p>&copy; {new Date().getFullYear()} Sandra Henriques</p>
      <a
        className="dg"
        rel="noreferrer"
        target="_blank"
        href="https://dhanishgajjar.com"
      >
        DG
      </a>
    </FooterStyles>
  );
}
