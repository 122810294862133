import styled from 'styled-components';

export const LogoStyles = styled.h1`
  color: var(--primary-color);
  font-family: 'Poppins', sans-serif;
  font-size: clamp(1.2rem, 4vw, 1.5rem);
  font-weight: 500;
  position: relative;
  &::before {
    content: '';
    background-color: var(--color-accent);
    position: absolute;
    height: 100%;
    width: 3px;
    left: 110%;
    top: 0;
    bottom: 0;
  }
`;
