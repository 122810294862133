import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --primary-color: hsl(0, 0%, 20%);
    --primary-color-light-60: hsl(0, 0%, 60%);
    --primary-color-light-90: hsl(0, 0%, 90%);
    --primary-color-light-95: hsl(0, 0%, 95%);
    --color-accent-dark-10: hsl(16, 100%, 10%);
    --color-accent-dark-30: hsl(16, 100%, 30%);
    --color-accent-dark-70: hsl(16, 100%, 70%);
    --color-accent: hsl(16, 100%, 50%);
    --color-accent-light-90: hsl(16, 100%, 90%);
    --color-accent-light-95: hsl(16, 100%, 95%);
  }
  * {
    box-sizing: border-box;
  }
  body {
    line-height: 1.4;
  }
  h2 {
    color: var(--color-accent);
    font-family: 'EB Garamond', serif;
  }
  .center {
    text-align: center;
  }
  .categories {
      font-family: 'Poppins', sans-serif;
      font-size: .75em;
      margin-right: 0.5rem;
  }
  .backButton {
    background: none;
    border: none;
    outline: none;
    a {
      border: 1px solid var(--primary-color);
      border-radius: 4px;
      color: var(--primary-color);
      display: inline-block;
      font-family: 'Poppins', sans-serif;
      margin: 1rem 0;
      padding: 0.25rem 0.75rem;
      text-decoration: none;
      &:hover {
        background-color: var(--primary-color);
        color: var(--primary-color-light-95);
      }
    }
    margin: auto;
  }
`;

export default GlobalStyles;
