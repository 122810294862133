import styled from 'styled-components';

export const NavStyles = styled.header`
	background-color: rgba(255 255 255 / 50%);
	align-items: center;
	display: grid;
	grid-template-columns: auto 1fr;
	max-width: 960px;
	margin: 2rem auto 0 auto;
	padding-left: clamp(1.2rem, 2vw, 2rem);
	padding-right: clamp(1.2rem, 2vw, 2rem);
	position: sticky;
	top: 0;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	z-index: 1;
	button[aria-expanded] {
		display: none;
	}
	a,
	li {
		text-decoration: none;
	}
	ul {
		display: flex;
		list-style: none;
		justify-content: end;
		padding: 0;
		li {
			a {
				color: #333;
				display: block;
				font-family: 'Poppins', sans-serif;
				font-weight: 400;
				margin-left: 1rem;
				position: relative;
				width: max-content;
				&:hover,
				&[aria-current='page'] {
					color: var(--color-accent);
				}
				&[aria-current='page']:before,
				&:hover:before {
					border-bottom: 3px dotted var(--color-accent);
					bottom: -5px;
					content: '';
					left: 0;
					position: absolute;
					width: 100%;
				}
			}
			a.activeLink {
				color: var(--color-accent);
				&:hover {
					color: var(--color-accent);
				}
				&:before,
				&:hover:before {
					border-bottom: 3px dotted var(--color-accent);
					bottom: -5px;
					content: '';
					left: 0;
					position: absolute;
					width: 100%;
				}
			}
		}
	}
	@media (max-width: 500px) {
		button[aria-expanded] {
			background: none;
			border: none;
			cursor: pointer;
			display: inline-block;
			height: 24px;
			margin-left: auto;
			outline: none;
			padding: 0;
			position: relative;
			width: 24px;
		}
		button[aria-expanded='false']:before,
		button[aria-expanded='false']:after,
		button[aria-expanded='true']:before,
		button[aria-expanded='true']:after {
			background: #333;
			bottom: 0;
			content: '';
			height: 2px;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			top: 0;
			transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
			width: 100%;
		}
		button[aria-expanded='false']:before {
			transform: translateY(-5px) rotate3d(0, 0, 0, 45deg);
		}

		button[aria-expanded='false']:after {
			transform: translateY(5px) rotate3d(0, 0, 0, -45deg);
		}

		button[aria-expanded='true']:before {
			transform: translateY(0px) rotate3d(0, 0, 1, 45deg);
		}

		button[aria-expanded='true']:after {
			transform: translateY(0px) rotate3d(0, 0, 1, -45deg);
		}

		button[aria-expanded='false']:before {
			transform: translateY(-5px) rotate3d(0, 0, 0, 45deg);
		}

		button[aria-expanded='false']:after {
			transform: translateY(5px) rotate3d(0, 0, 0, -45deg);
		}

		button[aria-expanded='true']:before {
			transform: translateY(0px) rotate3d(0, 0, 1, 45deg);
		}

		button[aria-expanded='true']:after {
			transform: translateY(0px) rotate3d(0, 0, 1, -45deg);
		}
		nav {
			order: 3;
			grid-column: 1 / -1;
			align-items: center;
			display: grid;
			grid-template-columns: 1fr;
			max-height: 0px;
			overflow: hidden;
			transition: max-height 0.5s ease-in-out;
		}
		nav ul {
			display: grid;
			grid-gap: 20px;
			grid-template-columns: subgrid;
			justify-items: center;
			margin: 1rem 0 2rem 0;
			padding: 0;
			width: 100%;
		}

		nav ul li a,
		nav ul li a:visited {
			text-align: center;
			margin-left: 0;
		}

		ul li:nth-child(1) {
			opacity: 0;
			transition: opacity 0.2s 0.1s ease-in;
		}

		ul li:nth-child(2) {
			opacity: 0;
			transition: opacity 0.2s 0.2s ease-in;
		}

		ul li:nth-child(3) {
			opacity: 0;
			transition: opacity 0.2s 0.3s ease-in;
		}

		li:nth-child(4) {
			opacity: 0;
			transition: opacity 0.2s 0.4s ease-in;
		}

		ul li:nth-child(5) {
			opacity: 0;
			transition: opacity 0.2s 0.5s ease-in;
		}

		[aria-expanded='true'] ~ nav {
			max-height: 200px;
		}

		[aria-expanded='true'] ~ nav ul li {
			opacity: 1;
			padding: 0;
		}

		[aria-expanded='false'] ~ nav {
			max-height: 0px;
		}

		[aria-expanded='false'] ~ nav ul li {
			opacity: 0;
			padding: 0;
		}
	}
`;
