import React from 'react';
import { LogoStyles } from '../styles/LogoStyles';

export default function Logo() {
  return (
    <LogoStyles>
      S. Henriques
    </LogoStyles>
  );
}
