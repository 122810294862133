import React from 'react';
import styled from 'styled-components';
import 'normalize.css';
import Nav from './Nav';
import Footer from './Footer';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

const WrapperStyles = styled.div`
  margin: auto;
  max-width: 960px;
  padding: clamp(1.2rem, 2vw, 2rem);
`;

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <Nav />
      <WrapperStyles>
        {children}
        <Footer />
      </WrapperStyles>
    </>
  );
}
